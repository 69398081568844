<template src="./menurights.html"></template>
<style scoped src="./menurights.css"></style>
<script>
import Loading from 'vue-loading-overlay'
import { mapActions, mapGetters } from 'vuex'
import ACLService from '@/services/ACLService'
import { required } from 'vuelidate/lib/validators'
import Utils from '@/utils/utils'
import modal from '@/components/modal/notification/modal'
import pagination from '@/components/vtable/vtpagination.vue'
import AppConstants from '../../../constants/AppConstants.vue'
import authService from  '../../../services/AuthenticationService.vue'

export default {
    components : {
        Loading, modal, pagination
    },
    data () {
        return {
            id: null,               //Id refers to Id field Group 
            groupId : null,         //Group Id
            groupName : null,       //Group Name
            groupCode : null,       //Group Code
            isActivated: null,      //GroupActivated or not 
            rightsList : [],        //list of Rights
            uiRightsList: [],       //Rights list shown to user with selected or not
            groupList: [],          // list of Groups
            loaderVisible: false,   // loader 
            columns: ['GroupCode', 'GroupName', 'IsActivated', 'Action'],
            options : {
                headings : {
                    GroupCode : 'Group Code',
                    GroupName : 'Group Name',
                    IsActivated : 'Is Activated'
                }
            },
            isAddAction: true,      // button for showing whether Add or Update
            selectedRights: [],     // this array contains selected rights at present
            assignedRights:[],       // this array contains selected rights before any change
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            isLazyLoadingEnabled: AppConstants.enableLazyLoading
        }
    },
    methods : {
        ...mapActions(['fetchRightsList','fetchGroupList','fetchGroupRightsList', 'fetchUserAcl', 'fetchUserDetails']),
        async updateGroupTable () {
            await this.fetchGroupList()
            this.groupList = await this.getGroupList
        },
        async addGroupRights () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return 
            }
            if (this.selectedRights.length < 1) {
                this.showAlert(null, 'Please Assign Rights')
                return 
            }
            this.$bvModal.msgBoxConfirm('Are you sure you want to Add the Group Information ?')
            .then(async result => {
                if(result) {
                    // Add Groups and add GroupRights
                    // create group
                    this.loaderVisible = true
                    let groupId = Utils.methods.uuidv4()
                    await ACLService.methods.addGroup({
                        "id": Utils.methods.uuidv4(),
                        "GroupId": groupId,
                        "GroupCode": this.groupCode.trim(),
                        "GroupName": this.groupName.trim(),
                        "IsActivated": this.isActivated ? "1": "0"
                    })
                    //add GroupRights for that group
                    this.selectedRights.forEach(async sr => {
                        await ACLService.methods.createGroupRightsItem({
                            'id':Utils.methods.uuidv4(),
                            'groupRightId': Utils.methods.uuidv4(),
                            'groupId': groupId,
                            'rightId': sr,
                            'rightCode': this.rightsList.find(right=> right.RightId === sr).RightCode
                        })
                    })
                    this.showAlert(1, 'Group Created Successfully')
                    this.clearAll()
                    this.updateGroupTable()
                    this.loaderVisible = false
                }
            })
        },
        async editGroupInfo (groupInfo) {
            this.loaderVisible = true
            let assignList = []
            let availList = []
            this.id = groupInfo.id
            this.groupId = groupInfo.GroupId
            this.groupName = groupInfo.GroupName
            this.groupCode = groupInfo.GroupCode
            this.isActivated = groupInfo.IsActivated

            // get group information
            let groupResult = this.groupList.find(group => group.GroupCode === this.groupCode && group.GroupName === this.groupName)
            await this.fetchGroupRightsList()
            let groupRightsResult = await this.getGroupRightsList.filter(gr => gr.groupId === groupResult.GroupId)

            if(this.rightsList?.length > 0) {
                this.rightsList.forEach( right => {
                   if(groupRightsResult.find(grl => grl.rightId === right.RightId)) {
                       assignList.push({
                           'id': right.id,
                           'RightCode': right.RightCode,
                           'RightId' : right.RightId,
                           'RightDescription': right.RightDescription,
                           'checked': true
                       })
                       this.selectedRights.push(right.RightId)
                   }else{
                       availList.push({
                           'id': right.id,
                           'RightCode': right.RightCode,
                           'RightId' : right.RightId,
                           'RightDescription': right.RightDescription,
                           'checked': false
                       })
                   }
                })
            }
            this.assignedRights  = [...this.selectedRights]
            this.uiRightsList = assignList.concat(availList)
            this.isAddAction = false
            this.loaderVisible = false
        },
        updateRightsList (item, e) {
            if(e) {
                if(!this.selectedRights.includes(item.RightId)) {
                    this.selectedRights.push(item.RightId)
                }
            } else {
                if(this.selectedRights.includes(item.RightId)) {
                    this.selectedRights.splice(this.selectedRights.indexOf(item.RightId), 1)
                }
            } 
        },
        async updateGroupRights () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return 
            }
           if (this.selectedRights.length < 1) {
                this.showAlert(null, 'Please Assign Rights')
                return 
            }
            this.$bvModal.msgBoxConfirm('Are you sure you want to update the Group Information ?')
            .then(async result => {
                if (result) {
                    try {
                        this.loaderVisible = true
                        // updating Groups and Grouprights
                        await ACLService.methods.updateGroup({
                            "id": this.id,
                            "GroupCode": this.groupCode.trim(),
                            "GroupName": this.groupName.trim(),
                            "IsActivated": this.isActivated === 'true' || this.isActivated === true ? "1": "0"
                        })
                        //getting newly selected Rights and newly removed rights
                        let newlyRemovedRights = this.assignedRights.filter(ar => !this.selectedRights.includes(ar))
                        let newlySelectedRights = this.selectedRights.filter(sr => !this.assignedRights.includes(sr))                    
                    
                        // retrieve items from GroupRights which are going to be removed
                        let removedItems = this.getGroupRightsList.filter(grl => grl.groupId === this.groupId && newlyRemovedRights.includes(grl.rightId))
                        // remove items from GroupRights
                        removedItems.forEach(async data => {
                            await ACLService.methods.deleteGroupRights({
                                'id': data.id
                            }).then( () => {})
                            .catch(err=> {})
                        })

                        //add items to GroupRights 
                        newlySelectedRights.forEach(async item => {
                            await ACLService.methods.createGroupRightsItem({
                                'id':Utils.methods.uuidv4(),
                                'groupRightId': Utils.methods.uuidv4(),
                                'groupId': this.groupId,
                                'rightId': item,
                                'rightCode': this.rightsList.find(right=> right.RightId === item).RightCode
                            })
                        })
                        this.loaderVisible = false
                        this.showAlert(1, 'Group Successfully Updated')
                        this.updateGroupTable()
                        await this.fetchUserDetails(this.$session.get('userEmail'))
                        if(this.groupId === this.getUserDetails.GroupId) {
                            authService.methods.logout()
                            location.reload()
                            // this.$router.push({ name: 'Login'})
                            // await this.fetchUserAcl({
                            //     userEmail: this.$session.get('userEmail'),
                            //     groupId:  await this.getUserDetails.GroupId
                            // })
                            // localStorage.setItem('userAclRights', JSON.stringify(await this.getUserAclRights))
                            // let enabledRoutes = []
                            // this.$router.options.routes.forEach(route => {
                            //     if(localStorage.getItem('userAclRights')) {
                            //         if( (localStorage.getItem('userAclRights')).includes(route.meta?.rightsCode)) {
                            //             route.meta.access = true
                            //             if(route.meta.rightsCode) enabledRoutes.push(route)
                            //         } 
                            //     }
                            // })
                            // this.$store.commit('storeEnabledRoutes', enabledRoutes) 
                            // if(this.getEnabledRoutesName.includes('MenuRights')){
                            //     location.reload()
                            // }else{
                            //     let route = this.getEnabledRoutes.find(route => route.name === this.getNextRoute)
                            //     let url = location.href.replace('/menurights',route.path || 'viewprofile' )
                            //     window.location.href = url
                            // }
                        }
                        this.clearAll()
                        // this.loaderVisible = false                        
                    } catch (error) {
                        this.loaderVisible = false
                    }
                }
            })
        },
        async deleteGroupInfo (groupInfo) {
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete group Information')
            .then(async result => {
                if(result) {
                    this.loaderVisible = true
                    await ACLService.methods.updateGroup({
                        "id": groupInfo.id,
                        "IsActivated": "0"
                    }).then(() => this.showAlert(1, 'Group Successfully Deleted'))
                    .catch(() => this.showAlert(null, 'Group Not Deleted'))
                    this.updateGroupTable()
                    this.clearAll()
                    this.loaderVisible = false
                }
            })
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        clearAll() {
            this.id = null
            this.groupId = null
            this.groupName = null
            this.groupCode = null
            this.isActivated = 0
            this.isAddAction = true
            this.uiRightsList = this.rightsList
            this.uiRightsList.forEach(item => {
                item.checked = 'false'
            })
            this.assignedRights = []
            this.selectedRights = []
            this.$v.$reset()
        }
    },
    async mounted () {
        this.loaderVisible = true
        if(!this.getRightsList || this.getRightsList.length < 1) await this.fetchRightsList()
        this.rightsList = await this.getRightsList
        this.uiRightsList = this.rightsList
        await this.updateGroupTable()
        this.loaderVisible = false
    }, 
    computed : {
        ...mapGetters(['getEnabledRoutes', 'getEnabledRoutesName', 'getNextRoute', 'getRightsList','getGroupList', 'getGroupRightsList','getUserAclRights', 'getUserDetails']),
        groupListTable() {
            let groupList = []
            if (this.groupList && this.groupList.length > 0) {
                groupList = JSON.parse(JSON.stringify(this.groupList))
                if (this.isLazyLoadingEnabled) {
                    let selectedPage = this.$refs.paginationRef.selectedPage
                    groupList = groupList.slice((selectedPage - 1) * 10, selectedPage * 10)
                }
                groupList.forEach(group => {
                    group.IsActivated = group.IsActivated === "1" ? 'true' : 'false'
                })
            }
            const updateTotalRecords = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.groupList?.length ?? 0
                }
            }
            this.isLazyLoadingEnabled ? updateTotalRecords() : null
            return groupList
        }
    },
    validations : {
        groupCode : {required},
        groupName : {required}
    }
}
</script>